import { stripRichTextWrapperTag } from '../../utils/html';

export default ({ primary, items }, data, { $enhancedLinkSerializer }) => ({
  title: stripRichTextWrapperTag(primary.documents_list_title),
  docs: items
    .filter(
      ({ document_title, document_file }) =>
        document_title &&
        document_file &&
        (document_file.url || document_file.cdn_link)
    )
    .map((item) => {
      return {
        title: stripRichTextWrapperTag(item.document_title),
        link: $enhancedLinkSerializer({
          ...item.document_file,
          url: item.document_file.url || item.document_file.cdn_link,
        }),
      };
    }),
});
